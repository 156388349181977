// import sanityClient from '@sanity/client';
// import imageUrlBuilder from '@sanity/image-url';

// export const client = sanityClient({
//   projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
//   dataset: 'production',
//   apiVersion: '06-21-2023',
//   useCdn: true,
//   token: process.env.REACT_APP_SANITY_TOKEN,
// });

import {createClient} from '@sanity/client'
 import imageUrlBuilder from '@sanity/image-url';
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: '2023-06-03', // use current date (YYYY-MM-DD) to target the latest API version
    token: process.env.REACT_APP_SANITY_TOKEN
})

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);